import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import React, { useState } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

const ExportOptions = ({ mesas }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen(!dropdownOpen);

    const exportToPDF = () => {
        const doc = new jsPDF();
        doc.text("Lista de Mesas", 20, 10);
        doc.autoTable({
            head: [['#', 'Ubicación', 'Número de Mesa', 'Estado']],
            body: mesas.map((mesa, i) => [
                i + 1,
                mesa.ubicacion_mesa,
                mesa.numero_mesa,
                mesa.estado_mesa,
            ]),
        });
        doc.save("mesas.pdf");
    };

    const exportToExcel = () => {
        // Crear un nuevo libro de trabajo
        const workbook = XLSX.utils.book_new();
        // Crear una hoja de trabajo con los datos de las mesas
        const worksheet = XLSX.utils.json_to_sheet(
            mesas.map((mesa, i) => ({
                "#": i + 1,
                "Ubicación de Mesa": mesa.ubicacion_mesa,
                "Número de Mesa": mesa.numero_mesa,
                "Estado de Mesa": mesa.estado_mesa
            }))
        );
        // Agregar la hoja de trabajo al libro
        XLSX.utils.book_append_sheet(workbook, worksheet, "Mesas");

        // Generar un archivo Excel y guardarlo
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        saveAs(blob, "mesas.xlsx");
    };

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle caret style={{ color: '#00929e' }}>
                <i className="ni ni-collection fa-2x" />
            </DropdownToggle>

            <DropdownMenu>
                <DropdownItem onClick={exportToPDF}>Exportar a PDF</DropdownItem>
                <DropdownItem onClick={exportToExcel}>Exportar a Excel</DropdownItem>
            </DropdownMenu>
        </Dropdown>
    );
};

export default ExportOptions;
