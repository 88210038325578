import React, { useState, useEffect } from 'react';
import { FormGroup, Col, Row } from "reactstrap";
import myStyles from "../../../../assets/css/myStyles.module.css";

export const GroupedTableSelection = ({ mesas, onChange }) => {
  const [groupedMesas, setGroupedMesas] = useState({});
  const [selectedMesas, setSelectedMesas] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!mesas || mesas.length === 0) {
      setError("No hay mesas disponibles.");
      return;
    }

    try {
      const grouped = mesas.reduce((acc, mesa) => {
        if (!acc[mesa.ubicacion_mesa]) {
          acc[mesa.ubicacion_mesa] = [];
        }
        acc[mesa.ubicacion_mesa].push(mesa);
        return acc;
      }, {});
      setGroupedMesas(grouped);
      setError(null);
    } catch (err) {
      console.error("Error al agrupar mesas:", err);
      setError("Hubo un error al procesar las mesas. Por favor, inténtelo de nuevo.");
    }
  }, [mesas]);

  const handleMesaChange = (mesaId) => {
    setSelectedMesas(prev => {
      const newSelection = prev.includes(mesaId)
        ? prev.filter(id => id !== mesaId)
        : [...prev, mesaId];
      
      if (onChange && typeof onChange === 'function') {
        onChange(newSelection);
      }
      return newSelection;
    });
  };

  if (error) {
    return <div className="text-danger">{error}</div>;
  }

  if (Object.keys(groupedMesas).length === 0) {
    return <div>Cargando mesas...</div>;
  }

  return (
    <FormGroup className={myStyles.Inputgroup}>
      {Object.entries(groupedMesas).map(([ubicacion, mesasGroup]) => (
        <div key={ubicacion} className="mb-3">
          <h6 className="text-muted">{ubicacion}</h6>
          <div className={myStyles.mesasGrid}>
            {mesasGroup.map((mesa) => (
              <div key={mesa.id} className={myStyles.mesaWrapper}>
                <label className={`${myStyles.mesaCheckbox} ${!mesa.estado_mesa ? myStyles.mesaOcupada : ''}`}>
                  <input
                    type="checkbox"
                    value={mesa.id}
                    disabled={!mesa.estado_mesa}
                    checked={selectedMesas.includes(mesa.id)}
                    onChange={() => handleMesaChange(mesa.id)}
                  />
                  <span className={myStyles.mesaNumber}>
                    {mesa.numero_mesa}
                  </span>
                </label>
              </div>
            ))}
          </div>
        </div>
      ))}
    </FormGroup>
  );
};
export default GroupedTableSelection;
