import React, { useState } from "react"; 
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

const ExportReservationOptions = ({ reservations }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(!dropdownOpen);

    const exportToPDF = () => {
        const doc = new jsPDF();
        const tableColumn = [
            "ID",
            "Cliente",
            "Fecha Reserva",
            "Hora Reserva",
            "Adultos",
            "Niños",
            "Estado"
        ];
        const tableRows = reservations.map(reservation => [
            reservation.id,
            `${reservation.client.name} ${reservation.client.lastname}`,
            reservation.fecha_reserva,
            reservation.hora_reserva,
            reservation.cant_adultos,
            reservation.cant_ninos,
            reservation.estado_reserva,
        ]);

        doc.autoTable(tableColumn, tableRows, { startY: 20 });
        doc.text("Lista de Reservas", 14, 15);
        doc.save("reservas.pdf");
    };

    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(
            reservations.map(reservation => ({
                ID: reservation.id,
                Cliente: `${reservation.client.name} ${reservation.client.lastname}`,
                Fecha: reservation.fecha_reserva,
                Hora: reservation.hora_reserva,
                Adultos: reservation.cant_adultos,
                Niños: reservation.cant_ninos,
                Estado: reservation.estado_reserva,
            }))
        );
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Reservas");
        XLSX.writeFile(workbook, "reservas.xlsx");
    };

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle caret style={{ color: '#00929e' }}>
                <i className="ni ni-collection fa-2x" />
            </DropdownToggle>

            <DropdownMenu>
                <DropdownItem onClick={exportToPDF}>Exportar a PDF</DropdownItem>
                <DropdownItem onClick={exportToExcel}>Exportar a Excel</DropdownItem>
            </DropdownMenu>
        </Dropdown>
    );
};

export default ExportReservationOptions;
